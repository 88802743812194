import CryptoJS from 'crypto-js';

export const menu_profile = (menu_profile_id: number) => {
  switch (menu_profile_id) {
    case 2: return 'ETAPA';
    case 10: return 'Professor';
    case 20: return 'Coordenador';
    case 21: return 'Orientador';
    case 22: return 'Diretor Pedagógico';
    case 23: return 'Coordenador/Logística';
    case 30: return 'Gestão Logística';
    case 40: return 'Gestão Log/Financeira';
    case 50: return 'Gestão de Marketing';
    case 60: return 'Gestão de ADM/Secretaria';
    case 70: return 'Mantenedor';
    case 71: return 'Direção Geral';
    case 80: return 'Não definido';
    case 90: return 'Somente cadastro';
    case 91: return 'Visitante';
    case 92: return 'Acessor';
    case 93: return 'Acessor Pedagógico';
    case 700: return 'Aluno';
    default: return 'Não definido';
  }
}

export const userData = () => {
  const userCrypto = localStorage.getItem('userInfo');
  if (!userCrypto) return null;
  const userData = decrypt(userCrypto);
  return userData ? JSON.parse(userData) : null;
}

export const crypto = (text: string) => {
  const key = '$E#@A&T*2025$';
  const cipher = CryptoJS.AES.encrypt(text, key);
  return cipher.toString();
}

export const decrypt = (text: string) => {
  const key = '$E#@A&T*2025$';
  const decipher = CryptoJS.AES.decrypt(text, key);
  return decipher.toString(CryptoJS.enc.Utf8);
}