import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { documentList } from './data';
import Toast from '../../../Common/Helpers/Toast';
import { CustomGridToolbarProps, ResponseProps } from '../../../@types/types';
import { successToast } from '../../../Common/Helpers/Utils';
import { userData } from '../../../Components/User';

interface DataProps {
  data: GridRowsProp;
  onUpdate: (newData: any) => Promise<ResponseProps>;
}

export default function CrudSettings({ data, onUpdate }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [mode, setMode] = React.useState<'view' | 'edit' | 'new'>('view');
  const [loading, setLoading] = React.useState(false);
  const { user } = userData();

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      setMode('view');
    }
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    if (mode === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      return;
    }
    setMode('edit');
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setMode('view');
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setLoading(true);

    const response = await onUpdate(updatedRow);

    if (response.status === 'success') {
      successToast(response.message);
      setLoading(false);
      setMode('view');
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } else {
      setMessage(response.message);
      setOpen(true);
      setLoading(false);
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleVerifyEditMode = (params: any) => {
    if (user.menu_profile_id !== 2) handleCancelClick(params.id)();

    if (mode === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      
      handleCancelClick(params.id)();
      setMode('edit');
      return;
    } else {
      setMode('edit');
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'url_conheca',
      minWidth: 300,
      flex: 1,
      editable: user.menu_profile_id === 2,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Termos de Uso</strong>,
      renderCell: (params) => (
        user.menu_profile_id === 2
          ? <a href={params.value} style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          : <Button
              variant="contained"
              color="primary"
              size="small"
              href={params.value}
              target="_blank"
              rel="noreferrer"
            >
              Acessar
            </Button>
      ),
    },
    {
      field: 'url_tutorial',
      minWidth: 300,
      flex: 1,
      editable: user.menu_profile_id === 2,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Política de Privacidade</strong>,
      renderCell: (params) => (
        user.menu_profile_id === 2
          ? <a href={params.value} style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          : <Button
              variant="contained"
              color="primary"
              size="small"
              href={params.value}
              target="_blank"
              rel="noreferrer"
            >
              Acessar
            </Button>
      ),
    },
    user.menu_profile_id === 2 &&
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Ações</strong>,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            disabled={user.menu_profile_id !== 2}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            disabled={user.menu_profile_id !== 2}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Toast
        message={message}
        open={open}
        setclose={setOpen}
        severity="error"
      />
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        columnVisibilityModel={{ conteudo: false, url: true }}
        editMode="row"
        onRowDoubleClick={handleVerifyEditMode}
        autosizeOptions={{
          columns: ['url_conheca', 'url_tutorial'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhuma configuração cadastrada.',
          footerRowSelected: (count) => `${count} url(s) selecionada(s)`,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            rowsPerPageOptions: [1],
            labelRowsPerPage: "Documentos por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}