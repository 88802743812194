import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { ImageUploader, MultipleSelect } from '../../../Components/Medias';
import Swal from 'sweetalert2';
import { successToast } from '../../../Common/Helpers/Utils';
import { JogosProps, ResponseProps } from './types';
import Toast from '../../../Common/Helpers/Toast';
import { CircularProgress } from '@mui/material';
import LevelsOperations from '../../../InfraStructure/Levels/LevelsOperations';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../InfraStructure/store';
import { CustomGridToolbarProps, LevelProps } from '../../../@types/types';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

type DataProps = {
  data: GridRowsProp;
  onSave: (data: any) => Promise<ResponseProps>;
  onUpdate: (data: any) => Promise<ResponseProps>;
  onDelete: (id: GridRowId) => Promise<boolean>;
}

export default function CrudJogos({ data, onSave, onUpdate, onDelete }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const mode = React.useRef<'view' | 'edit' | 'new'>('view') as React.MutableRefObject<'view' | 'edit' | 'new'>;
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [levels, setLevels] = React.useState<LevelProps[]>([]);
  const [loading, setLoading] = React.useState({ id: undefined, loading: false });

  const dispatch = useDispatch<AppDispatch>();
  const {
    Data: DataLevels,
  } = useSelector((state: any) => state.levels);

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => {
        const newRows = [{
          id,
          nome: '',
          nivel_id: '',
          url: '',
          imagem: '',
          created_at: new Date().toLocaleDateString(),
          isNew: true
        }, ...oldRows];
        return newRows;
      });
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nome' },
      }));

      mode.current = 'new';
    };

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          disabled={loading.loading || mode.current !== 'view'}
          onClick={handleClick}>
          Adicionar
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      mode.current = 'view';
    }
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleValidationData = (data: JogosProps) => {
    const { nome, url, imagem, nivel_id } = data;
    const validUrl = new RegExp(
      '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
      'i'
    );

    if (!nome) {
      return { isValid: false, message: 'Nome é obrigatório' };
    }
    if (nivel_id.length < 1) {
      return { isValid: false, message: 'Nível é obrigatório' };
    }
    if (!url || !validUrl.test(url)) {
      return { isValid: false, message: 'Url inválida' };
    }
    if (imagem.length < 1) {
      return { isValid: false, message: 'Imagem é obrigatória' };
    }

    return { isValid: true, message: '' };
  }

  const handleEditClick = (id: GridRowId) => () => {
    if (mode.current === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      return;
    }
    mode.current = 'edit';
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: 'Atenção',
      text: "Deseja mesmo apagar o Jogo?",
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, apagar!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading({ id, loading: true });
        const response = await onDelete(id);
        if (response === true) {
          setRows(rows.filter((row) => row.id !== id));
          successToast('Jogo apagado com sucesso!');
          setLoading({ id, loading: false });
        } else {
          setOpen(true);
          setMessage('Erro ao apagar o jogo.');
          setLoading({ id, loading: false });
        }
      }
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    mode.current = 'view';
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleLevels = async () => {
    dispatch(LevelsOperations.getLevels());
  }

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = {
      ...newRow,
      id: newRow.id,
      isNew: false,
      imagem: typeof newRow.imagem === 'object' ? newRow.imagem : '',
    };

    if (mode.current === 'new') {
      const validation = handleValidationData(updatedRow);

      if (!validation.isValid) {
        setOpen(true);
        setMessage(validation.message);
        return null;
      }

      setLoading({ id: newRow.id, loading: true });

      const response: ResponseProps = await onSave(updatedRow);

      if (response.status === 'success') {
        mode.current = 'view';
        successToast(response.message);

        const returnedRow = {
          ...updatedRow,
          nivel: response.data.nivel,
          nivel_id: newRow.nivel_id,
          imagem: response.data.imagem,
          criado_em: response.data.criado_em,
        };

        setRows(rows.map((row) => (row.id === newRow.id ? returnedRow : row)));
        setLoading({ id: newRow.id, loading: false });
        return returnedRow;
      } else {
        setOpen(true);
        setMessage(response.message);
        setLoading({ id: newRow.id, loading: false });
      }
    } else {
      if (newRow?.nivel_id.length < 1) {
        setOpen(true);
        setMessage('Nível é obrigatório');
        return null;
      }

      setLoading({ id: newRow.id, loading: true });

      const response: ResponseProps = await onUpdate(updatedRow);

      if (response.status === 'success') {
        mode.current = 'view';
        successToast(response.message);

        const returnedRow = {
          ...updatedRow,
          nivel: response.data.nivel,
          nivel_id: newRow.nivel_id,
          imagem: response.data.imagem,
        };

        setRows(rows.map((row) => (row.id === newRow.id ? returnedRow : row)));
        setLoading({ id: newRow.id, loading: false });
        return returnedRow;
      } else {
        setOpen(true);
        setMessage(response.message);
        setLoading({ id: newRow.id, loading: false });
      }
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleVerifyEditMode = (params: any) => {
    if (mode.current === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);

      handleCancelClick(params.id)();
      mode.current = 'edit';
      return;
    }
    mode.current = 'edit';
  }

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  React.useEffect(() => {
    handleLevels();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (DataLevels) {
      setLevels(DataLevels);
    }
  }, [DataLevels]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      type: 'number',
    },
    {
      field: 'nome',
      minWidth: 160,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Nome</strong>,
    },
    {
      field: 'nivel_id',
      minWidth: 180,
      flex: 1,
      editable: true,
      type: 'custom',
      headerAlign: 'left',
      renderHeader: () => <strong>Curso</strong>,
      renderCell: (params) => {
        return levels.find((item: LevelProps) => item.id === Number(params.value[0]))?.nivel;
      },
      renderEditCell(params) {
        const fieldParams = {
          ...params,
          items: levels,
          column: 'nivel_id',
          nome: 'nivel',
        }

        return <MultipleSelect params={fieldParams} />
      },
    },
    {
      field: 'url',
      type: 'string',
      minWidth: 250,
      flex: 1,
      editable: true,
      headerAlign: 'left',
      align: 'left',
      renderHeader: () => <strong>Url externa</strong>,
    },
    {
      field: 'imagem',
      type: 'string',
      minWidth: 150,
      flex: 1,
      editable: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Imagem</strong>,
      renderCell: (params) => {
        const data = {
          ...params,
          height: 60,
        }
        return <ImageUploader params={data} />
      },
      renderEditCell: (params) => {
        const data = {
          ...params,
          height: 60,
        }
        return <ImageUploader params={data} />
      }
    },
    {
      field: 'criado_em',
      headerAlign: 'center',
      type: 'string',
      minWidth: 180,
      flex: 1,
      editable: true,
      align: 'center',
      renderHeader: () => <strong>Criado em</strong>,
    },
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Ações</strong>,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={(loading.id === id && loading.loading) ? <CircularProgress size={16} /> : <SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
              disabled={loading.loading}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              disabled={loading.loading}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={(loading.id === id && loading.loading) ? <CircularProgress size={16} /> : <EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            disabled={loading.loading}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            disabled={loading.loading}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Toast
        message={message}
        open={open}
        setclose={setOpen}
        severity="error"
      />
      <DataGrid
        rows={rows}
        columns={columns}
        getRowHeight={() => 64}
        editMode="row"
        disableColumnMenu
        onRowDoubleClick={handleVerifyEditMode}
        columnVisibilityModel={{
          criado_em: mode.current === 'view',
          id: false,
        }}
        autosizeOptions={{
          columns: ['nome', 'nivel', 'url', 'imagem', 'created_at'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhum jogo cadastrado ainda.',
          footerRowSelected: (count) => `${count} jogo(s) selecionado(s)`,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar as unknown as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Jogos por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}