import { Box, Button, FilledInput, FormControl, InputLabel, Typography } from "@mui/material";
import React from "react";
import AccountMenu from "../AccountMenu";
import { ArrowBack } from "@mui/icons-material";
import { menu_profile, userData } from "../../Components/User";

export default function Administrator() {
  const userInfo = userData();
  const { user } = userInfo;

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ padding: 2 }}>
        <Typography variant="h5" marginTop={2}>{`${user.nome} - ${menu_profile(user.menu_profile_id)}`}</Typography>

        <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between", marginTop: 2 }}>
          <FormControl sx={{ width: "48%", minWidth: 320, m: 1 }} variant="filled">
            <InputLabel sx={{ color: "#A7A7A7", fontSize: 16 }} htmlFor="filled-adornment-password">
              Nome
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              sx={{ backgroundColor: "transparent", fontSize: 14 }}
              type="text"
              value={user.nome}
              disabled
            />
          </FormControl>
          
          <FormControl sx={{ width: "48%", minWidth: 320, m: 1 }} variant="filled">
            <InputLabel sx={{ color: "#A7A7A7", fontSize: 16 }} htmlFor="filled-adornment-password">
              E-mail
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              sx={{ backgroundColor: "transparent", fontSize: 14 }}
              type="text"
              value={user.email}
              disabled
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between", marginTop: 2 }}>
          <FormControl sx={{ width: "48%", minWidth: 320, m: 1 }} variant="filled">
            <InputLabel sx={{ color: "#A7A7A7", fontSize: 16 }} htmlFor="filled-adornment-password">
              Senha
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              sx={{ backgroundColor: "transparent", fontSize: 14 }}
              type="password"
              value="123456789"
              disabled
            />
          </FormControl>
          
          <FormControl sx={{ width: "48%", minWidth: 320, m: 1 }} variant="filled">
            <InputLabel sx={{ color: "#A7A7A7", fontSize: 16 }} htmlFor="filled-adornment-password">
              Confirmar senha
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              sx={{ backgroundColor: "transparent", fontSize: 14 }}
              type="password"
              value="123456789"
              disabled
            />
          </FormControl>
        </Box>
      </Box>

      <Button
        variant="contained"
        startIcon={<ArrowBack />}
        onClick={() => window.history.back()}
        sx={{ margin: 2 }}>
        Voltar
      </Button>
    </React.Fragment>
  )
}