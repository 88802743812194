import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import Toast from '../../../Common/Helpers/Toast';
import { relativeDate, successToast, telephoneValidator } from '../../../Common/Helpers/Utils';
import { CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import { CustomGridToolbarProps } from '../../../@types/types';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

type DataProps = {
  data: GridRowsProp;
  onSave?: (data: any) => Promise<boolean>;
  onUpdate?: (data: any) => Promise<boolean>;
  onDelete?: (id: GridRowId) => Promise<boolean>;
  loading?: boolean;
}

export const SchoolCRUDGrid = ({ data, onSave, onUpdate, onDelete, loading }: DataProps) => {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [mode, setMode] = React.useState<'edit' | 'view' | 'new'>('view');

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [
        ...oldRows,
        { id, nome: '', telefone: '', endereco: '', isNew: true },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nome' },
      }));
      setMode('new');
    };
  
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || mode !== 'view'}
          startIcon={<AddIcon />}
          onClick={handleClick}>
          Adicionar
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleValidationData = (data: any) => {
    const { nome, telefone, endereco } = data;
    if (!nome) {
      setOpen(true);
      setMessage('Nome da escola é obrigatório.');
      return false;
    }

    if (!telefone) {
      setOpen(true);
      setMessage('Telefone obrigatório.');
      return false;
    }

    if (!telephoneValidator(telefone)) {
      setOpen(true);
      setMessage('Telefone inválido. Ex.: 1144445555.');
      return false;
    }

    if (!endereco) {
      setOpen(true);
      setMessage('Endereço obrigatório.');
      return false;
    }

    return true;
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      setMode('view');
    }
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    if (mode === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      return;
    }
    // setMode('edit');
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = async (id: GridRowId) => {
    const response = await onDelete(id);

    if (response === true) {
      setRows(rows.filter((row) => row.id !== id));
      successToast('Escola apagada com sucesso!');
    }
  };

  const handleConfirmDelete = (id: GridRowId) => () => {
    Swal.fire({
      title: 'Atenção',
      text: 'Tem certeza que deseja excluir essa escola?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteClick(id);
      }
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setMode('view');
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    const validation = handleValidationData(updatedRow);
    let message = '';

    if (!validation) {
      return;
    }

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    if (mode === 'new') {
      const response = await onSave(updatedRow);
      if (response === true) {
        setMode('view');
        successToast('Escola salva com sucesso!');
        return updatedRow;
      }
    } else {
      const response = await onUpdate(updatedRow);
      if (response === true) {
        setMode('view');
        successToast('Escola atualizada com sucesso!');
        return updatedRow;
      }
    }
  };

  const handleVerifyEditMode = (params: any) => {
    handleCancelClick(params.id)();
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: 'nome',
      minWidth: 250,
      flex: 1,
      editable: false,
      renderHeader: () => <strong>Nome</strong>,
    },
    {
      field: 'telefone',
      type: 'string',
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      renderHeader: () => <strong>Telefone</strong>,
    },
    {
      field: 'endereco',
      type: 'string',
      minWidth: 180,
      flex: 1,
      editable: false,
      renderHeader: () => <strong>Endereço</strong>,
    },
    {
      field: 'criado_em',
      type: 'string',
      minWidth: 100,
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Criado em</strong>,
      // renderCell: (params) => (
      //   <span>
      //     {Object.keys(rowModesModel).length < 1 ? relativeDate(params.value) : params.value}
      //   </span>
      // ),
    },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: 'Actions',
    //   minWidth: 100,
    //   cellClassName: 'actions',
    //   renderHeader: () => <strong>Ações</strong>,
    //   getActions: ({ id }) => {
    //     const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    //     if (isInEditMode) {
    //       return [
    //         <GridActionsCellItem
    //           icon={loading ? <CircularProgress size={16} /> : <SaveIcon />}
    //           label="Save"
    //           sx={{
    //             color: 'primary.main',
    //           }}
    //           onClick={handleSaveClick(id)}
    //         />,
    //         <GridActionsCellItem
    //           icon={<CancelIcon />}
    //           label="Cancel"
    //           className="textPrimary"
    //           onClick={handleCancelClick(id)}
    //           color="inherit"
    //         />,
    //       ];
    //     }

    //     return [
    //       <GridActionsCellItem
    //         icon={<EditIcon />}
    //         label="Edit"
    //         className="textPrimary"
    //         onClick={handleEditClick(id)}
    //         color="inherit"
    //       />,
    //       <GridActionsCellItem
    //         icon={<DeleteIcon />}
    //         label="Delete"
    //         onClick={handleConfirmDelete(id)}
    //         color="inherit"
    //       />,
    //     ];
    //   },
    // },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Toast
        message={message}
        open={open}
        setclose={setOpen}
        severity="error"
      />

      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        disableColumnMenu
        onRowDoubleClick={handleVerifyEditMode}
        columnVisibilityModel={{
          criado_em: mode === 'view',
          endereco: mode !== 'view',
        }}
        autosizeOptions={{
          columns: ['nome', 'telefone', 'endereco', 'created_at'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhuma escola encontrada.',
          footerRowSelected: (count) => `${count} escola(s) selecionada(s)`,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        // slots={{
        //   toolbar: EditToolbar as unknown as GridSlots['toolbar'],
        // }}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Escolas por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}