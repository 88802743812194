import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import QuestionsOperations from "../../InfraStructure/Questions/Operations";
import CrudQuestions from "./components/EditableQuestions";

export default function Questions() {
  const [open,setOpen] = React.useState<boolean>(false);
  const [message,setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.questions
  );

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getQuestions = async () => {
    try {
      await dispatch<any>(QuestionsOperations.getQuestions());
    } catch (error: string | unknown) {
      console.log('Erro buscando perguntas: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  const handleSaveQuestion = async (question: any) => {
    if (question.isNew) {
      try {
        const response = await dispatch<any>(QuestionsOperations.saveQuestion(question));
        return response;
      } catch (error: string | unknown) {
        console.log('Erro salvando pergunta: ', error);
        return { message: 'Erro na rede. Tente novamente...', status: 'error' };
      }
    } else {
      try {
        const response = await dispatch<any>(QuestionsOperations.updateQuestion(question));
        return response;
      } catch (error: string | unknown) {
        console.log('Erro atualizando pergunta: ', error);
        return { message: 'Erro na rede. Tente novamente...', status: 'error' };
      }
    }
  };

  const onDelete = async (id: number) => {
    try {
      const response = await dispatch<any>(QuestionsOperations.deleteQuestion(id));
      return true;
    } catch (error: string | unknown) {
      console.log('Erro deletando pergunta: ', error);
      return false;
    }
  }

  React.useEffect(() => {
    getQuestions();
  }, []);

  React.useEffect(() => {
    if (Data) {
      setFilteredData(Data);
    }
  }, [Data]);

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar perguntas' onClick={getQuestions} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Questões
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={270}
              placeHolder="Buscar por pergunta"
              data={Data}
              setSearch={setFilteredData}
              columnsToSearch={['pergunta']}
            />
          </Box>
          
          {IsLoading
            ? <SkeletonGrid />
            : <CrudQuestions
                data={filteredData}
                onSave={handleSaveQuestion}
                onDelete={onDelete}
              />}
        </Box>
      </Box>
    </React.Fragment>
  );
}