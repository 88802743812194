import { ResponseProps } from "../../@types/types";
import DocumentsService from "../../Common/Services/DocumentsService";
import { AppDispatch } from "../store";

interface DocumentsOperationsType {
  getDocuments: () => (dispatch: AppDispatch) => Promise<ResponseProps>;
  createDocument: (formData: FormData) => (dispatch: AppDispatch) => Promise<ResponseProps>;
  updateDocument: (formData: FormData) => (dispatch: AppDispatch) => Promise<ResponseProps>;
  deleteDocument: (id: number) => (dispatch: AppDispatch) => Promise<ResponseProps>;
}

const DocumentsOperations: DocumentsOperationsType = {
  getDocuments: () => async (dispatch: AppDispatch): Promise<ResponseProps> => {
    try {
      const response = await DocumentsService.getDocuments();
      return { status: 'success', data: response.data, message: 'Documentos encontrados com sucesso' };
    } catch (error: string | unknown) {
      console.log('Erro buscando documentos: ', error);
      return { status: 'error', message: 'Erro ao buscar os documentos' };
    }
  },

  createDocument: (formData: FormData) => async (dispatch: AppDispatch): Promise<ResponseProps> => {
    try {
      const response = await DocumentsService.createDocument(formData);
      return { status: 'success', message: 'Documento criado com sucesso', data: response.data };
    } catch (error: string | unknown) {
      console.log('Erro salvando documento: ', error);
      return { status: 'error', message: 'Erro ao salvar o documento' };
    }
  },

  updateDocument: (formData: FormData) => async (dispatch: AppDispatch): Promise<ResponseProps> => {
    try {
      const response = await DocumentsService.updateDocument(formData);
      return { status: 'success', message: 'Documento atualizado com sucesso' };
    } catch (error: string | unknown) {
      console.log('Erro atualizando documento: ', error);
      return { status: 'error', message: 'Erro ao atualizar o documento' };
    }
  },

  deleteDocument: (id: number) => async (dispatch: AppDispatch): Promise<ResponseProps> => {
    try {
      const response = await DocumentsService.deleteDocument(id);
      return { status: 'success', message: 'Documento apagado com sucesso' };
    } catch (error: string | unknown) {
      console.log('Erro apagando documento: ', error);
      return { status: 'error', message: 'Erro ao apagar o documento' };
    }
  }
}

export default DocumentsOperations;