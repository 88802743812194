import Api from "../Helpers/Api";

class BooksService {
  getBooks() {
    return Api.get(`/livros`);
  }

  createBook(formData: FormData) {
    return Api.post(`/livros`, formData);
  }

  updateBook(formData: FormData) {
    return Api.post(`/livros/update`, formData);
  }

  deleteBook(id: number) {
    return Api.get(`/livros/delete/${id}`);
  }
}

export default new BooksService();