import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Button, Typography } from "@mui/material";
import CrudDocuments from "./components/EditableDocuments";
import MUIRichTextEditor from "mui-rte";
import theme from "../../Theme";
import { convertToHTML } from "draft-convert";
import { makeStyles } from "@mui/styles";
import { ArrowBack, Save } from "@mui/icons-material";
import { SkeletonGrid } from "../../Components/Skeleton";
import { ResponseProps } from "../../@types/types";
import DocumentsOperations from "../../InfraStructure/Documents/Operations";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../InfraStructure/store";

const useStyles = makeStyles({
  editorWrapper: {
    position: "relative",
  },
  toolBar: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
    backgroundColor: "#fff",
  },
  editor: {
    paddingTop: 10,
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
});

export default function Documents() {
  const [showEdit, setShowEdit] = React.useState(false);
  const [info, setInfo] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  const getDocuments = async () => {
    setLoading(true);
    try {
      const response = await dispatch(DocumentsOperations.getDocuments());
      setData(response.data);
      setLoading(false);
    } catch (error: string | unknown) {
      console.log('Erro buscando documentos: ', error);
      setLoading(false);
      setError(true);
    }
  }

  const handleSave = async (data: any): Promise<ResponseProps> => {
    console.log(data);
    return { status: 'success', message: "Salvo com sucesso!", data };
  }

  const handleUpdate = async (data: any): Promise<ResponseProps> => {
    console.log(data);
    return { status: 'success', message: "Atualizado com sucesso!" };
  }

  const handleDelete = async (id: any): Promise<boolean> => {
    console.log(id);
    return true;
  }

  React.useEffect(() => {
    getDocuments();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (data) {
      console.log(data);
      setData(data);
    }
  }, [data]);

  const DocumentEdit = (props: any) => {
    const { id, value, api, field } = props;
    const [initialValues, setInitialValues] = React.useState<any>('');

    const classes = useStyles();

    const handleSave = (data: any) => {
      const contentData = data.getCurrentContent();
      const contentHTML = convertToHTML(contentData);
      console.log(contentHTML);
    }

    return (
      <Box sx={{ width: "100%", height: window.innerHeight - 194 }}>
        <Box sx={{ width: "100%", height: "90%", overflowY: "auto" }}>
          <Typography
            sx={{ color: theme.palette.primary.main, fontWeight: "bold", mt: 2, mb: 2 }}
            variant="h6">
            {info.nome}
          </Typography>
          <MUIRichTextEditor
            // defaultValue={initialValues}
            toolbar={true}
            onChange={setInitialValues}
            onSave={handleSave}
            classes={{
              toolbar: classes.toolBar,
              editor: classes.editor,
            }}
            controls={[
              "title",
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "link",
              "numberList",
              "bulletList",
              /*"highlight",*/
              "quote",
              /*"media", "upload-image",*/
              "undo",
              "redo",
            ]}
          />
        </Box>

        <Box sx={{ width: "100%", height: "10%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ m: 1 }}
            startIcon={<ArrowBack />}
            onClick={() => setShowEdit(false)}>
            Voltar
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Save />}
            sx={{ m: 1 }}
            onClick={() => handleSave(initialValues)}>
            Salvar
          </Button>
        </Box>
      </Box>
    )
  };

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Documentos
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
          </Box>
          
          {loading
            ? <SkeletonGrid />
            : <CrudDocuments
                data={data}
                onSave={handleSave}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
              />
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}