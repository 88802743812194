import { Box, Button, CircularProgress, LinearProgress, Typography } from "@mui/material"
import theme from "../../Theme";
import React from "react";
import { CheckCircle, Error } from "@mui/icons-material";
import { userData } from "../../Components/User";
import { navigateToUrl } from "single-spa";

type VerifyProps = {
  status: 'pending' | 'success' | 'error';
  message: string;
  value?: number;
}

interface CardProps {
  title: string;
  value: number;
  status?: 'error' | 'success' | 'pending';
}

type ProgressProps = {
  progress: VerifyProps
}

const Logout = () => {
  const backImage = require('../../assets/media/background.png');
  const logo = require('../../assets/media/logo.png');
  const students = require('../../assets/media/students.png');
  const [progressHash, setProgressHash] = React.useState<VerifyProps>({ status: "pending", message: "", value: 0 });
  const [progressEmail, setProgressEmail] = React.useState<VerifyProps>({ status: "pending", message: "", value: 0 });
  const [progressAccess, setProgressAccess] = React.useState<VerifyProps>({ status: "pending", message: "", value: 0 });
  const [userMessage, setUserMessage] = React.useState('Verificando dados');
  const [loading, setLoading] = React.useState(true);

  const handleUser = () => {
    if (userData) {
      setUserMessage('Deslogando usuário');
      setProgressHash({ status: 'success', message: 'Usuário logado', value: 50 });
      setProgressHash({ status: 'success', message: 'Usuário logado', value: 100 });
      setProgressEmail({ status: 'success', message: 'E-mail ok', value: 100 });
      setProgressAccess({ status: 'success', message: 'Acesso', value: 100 });
      setUserMessage('Usuário deslogado');
      navigateToUrl('/logout');

      const userData = localStorage.getItem('userInfo');
      if (userData) {
        localStorage.removeItem('userInfo');
        window.location.reload();
      }
    } else {
      setUserMessage('Usuário não logado');
      setProgressHash({ status: 'error', message: 'Usuário não logado', value: 100 });
    }
    setLoading(false);
  }

  const Card = ({ title, value, status }: CardProps) => {
    return (
      <div style={{
        width: 350,
        paddingInline: 10,
        backgroundColor: "#FFF",
        borderRadius: 10,
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        margin: 15,
      }}>
        <Typography variant="subtitle1" color={theme.palette.primary.main}>
          <strong>{title}</strong>
        </Typography>
        <LinearProgress variant="determinate" value={value} sx={{ width: "70%", }} />
        <Typography variant="subtitle2" color={theme.palette.primary.main}>
          {status === 'pending'
            ? `${value}%`
            : (
            <Box sx={{ mt: 1.2 }}>
              {status === 'error'
                ? <Error fontSize="small" color="error" sx={{ paddingBottom: -1 }} />
                : <CheckCircle fontSize="small" color="primary" sx={{ paddingBottom: -1 }} />
              }
            </Box>
          )}
        </Typography>
      </div>
    );
  };

  React.useEffect(() => {
    handleUser();
  }, []);

  return (
    <Box sx={{
      backgroundImage: `url(${backImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      width: '100%',
      height: window.innerHeight,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: "#181F53",
      display: 'flex',
    }}>
      <Box>
        <Box sx={{ textAlign: "center", margin: 1 }}>
          <img src={logo} />
        </Box>
        <Box sx={{
          backgroundColor: "#FFF7",
          borderRadius: 2,
          justifyContent: "center",
          paddingTop: 1,
          paddingBottom: 1,
          border: '3px solid #142878',
          }}>
          <Box sx={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "#142878",
            marginTop: -1,
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
          }}>
            <Typography variant="body1" fontSize={20} align="center" padding={1}>
              <strong style={{ color: "#fff"}}>{`${userMessage} `}</strong>
              {loading && <CircularProgress size={14} />}
            </Typography>
          </Box>
          <Card status={progressHash.status} title="User" value={progressHash.value} />

          {progressHash.status === "success" && (
            <Card status={progressEmail.status} title="E-mail" value={progressEmail.value} />
          )}

          {progressEmail.status === "success" && (
            <Card status={progressAccess.status} title="Acesso" value={progressAccess.value} />
          )}

          {progressAccess.status === 'success' && (
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color='primary'
                endIcon={<CheckCircle />}
              >
                Você saiu!
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{ textAlign: "center", margin: 1, marginTop: 4 }}>
          <img src={students} style={{ width: 180 }} />
        </Box>
      </Box>
    </Box>
  )
}

export default Logout;