import CoordinatorsTypes from "./Types";

const INITIAL_STATE = {
  Data: [],
  IsLoading: true,
  HasError: false,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case CoordinatorsTypes.SET_COORDINATORS_LOADING:
      return { ...state, IsLoading: true, HasError: false };
    case CoordinatorsTypes.SET_COORDINATORS_SUCCESS:
      return { Data: action.payload, IsLoading: false, HasError: false };
    case CoordinatorsTypes.SET_COORDINATORS_FAILURE:
      return { ...state, IsLoading: false, HasError: true };
    default: return state;
  }
}