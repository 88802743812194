import Api from "../Helpers/Api";

class SettingsService {
  getSettings() {
    return Api.get(`/configuracoes`);
  }

  updateSettings(formData: FormData) {
    return Api.post(`/configuracoes/update`, formData);
  }
}

export default new SettingsService();