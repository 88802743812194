import {
  Apartment,
  // Assignment,
  Book,
  Chat,
  EmojiPeople,
  EventAvailable,
  LocalLibrary,
  MenuBook,
  PeopleAlt,
  PermContactCalendar,
  RecentActors,
  SdCard,
  // Settings,
  SettingsVoice,
  Sms,
  SportsEsports,
  VideogameAsset,
  YouTube,
} from "@mui/icons-material";

export const menuEtapaIdioma = [
  {
    icon: <Apartment />,
    label: "Unidades Escolares",
    show: true,
    route: "/unidades-escolares",
  },
  {
    icon: <PermContactCalendar />,
    label: "Salas",
    show: true,
    route: "/salas",
  },
  {
    icon: <MenuBook />,
    label: "Capitulos",
    show: true,
    route: "/capitulos",
  },
  {
    icon: <EventAvailable />,
    label: "Tarefas",
    show: true,
    route: "/tarefas",
  },
  {
    icon: <SettingsVoice />,
    label: "Aulas de Speaking",
    show: true,
    route: "/aulas-de-speaking",
  },
  {
    icon: <Book />,
    label: "Conteudo Extra",
    show: true,
    subItem: [
      {
        icon: <SdCard />,
        label: "FlashCards",
        show: true,
        route: "/flash-cards",
      },
      {
        icon: <YouTube />,
        label: "Videos",
        show: true,
        route: "/videos",
      },
      {
        icon: <LocalLibrary />,
        label: "Books",
        show: true,
        route: "/books",
      }
    ],
  },
  {
    icon: <VideogameAsset />,
    label: "Jogos",
    show: true,
    subItem: [
      {
        icon: <SportsEsports />,
        label: "Jogos",
        show: true,
        route: "/jogos",
      },
      {
        icon: <Sms />,
        label: "Perguntas",
        show: true,
        route: "/questions",
      },
      {
        icon: <Chat />,
        label: "Feedback de Jogos",
        show: true,
        route: "/feedback-de-jogos",
      },
    ]
  },
  {
    icon: <PeopleAlt />,
    label: "Usuarios",
    show: true,
    subItem: [
      {
        icon: <EmojiPeople />,
        label: "Professores",
        show: true,
        route: "/professores",
      },
      {
        icon: <RecentActors />,
        label: "Alunos",
        show: true,
        route: "/alunos",
      },
    ]
  },
];