import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import AddIcon from '@mui/icons-material/Add';
import {
  // GridRowsProp,
  GridRowModesModel,
  // GridRowModes,
  DataGrid,
  GridColDef,
  // GridToolbarContainer,
  GridEventListener,
  // GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  // GridSlots,
} from '@mui/x-data-grid';
import {
  // randomId,
} from '@mui/x-data-grid-generator';
import { FeedbackProps } from './types';
import { CustomGridToolbarProps } from '../../../@types/types';

type DataProps = {
  data: FeedbackProps[];
};

export default function ShowFeedback({ data }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = {
      ...newRow,
      isNew: false,
      id: newRow.id,
      aluno: newRow.aluno,
      jogo: newRow.jogo,
      pergunta: newRow.pergunta,
      avaliacao: newRow.avaliacao,
      data: newRow.data,
    };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: 'aluno',
      minWidth: 180,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Aluno</strong>,
    },
    {
      field: 'jogo',
      minWidth: 180,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Jogo</strong>,
    },
    {
      field: 'nivel',
      minWidth: 180,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Curso</strong>,
    },
    {
      field: 'pergunta',
      minWidth: 250,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Pergunta</strong>,
    },
    {
      field: 'avaliacao',
      minWidth: 80,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Avaliação</strong>,
    },
    {
      field: 'created_at',
      headerAlign: 'center',
      type: 'string',
      minWidth: 180,
      flex: 1,
      editable: true,
      align: 'center',
      renderHeader: () => <strong>Data da avaliação</strong>,
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        disableColumnMenu
        autosizeOptions={{
          columns: ['aluno', 'jogo', 'pergunta', 'avaliacao', 'data'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhum feedback encontrado.',
          footerRowSelected: (count) => `${count} resposta(s) selecionada(s)`,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Feedbacks por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}