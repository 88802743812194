import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import './FormExercises.css';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import FormComponents from './FormComponents';
import { ExercisesProps } from '../../Exercises/components/types';
import { useNavigate, useParams } from 'react-router-dom';
import { Remove } from '@mui/icons-material';

type DataProps = {
  data: ExercisesProps;
  setData: (data: ExercisesProps) => void;
  types: any;
  onUpdate: ()=>void;
  onSave: ()=>void;
}

type QuestaoType = {
  alternativas: any[];
  type: string | number;
  titulo?: string;
}

type RespostaType = {
  alternativas: any[];
  type: string | number;
  resposta?: string;
}

export default function FormExercises({ data, setData, types, onUpdate, onSave }: DataProps) {
  const [exerciseType, setExerciseType] = React.useState<number>();
  const [questao, setQuestao] = React.useState<QuestaoType | null>({alternativas: [], type: ''});
  const [resposta, setResposta] = React.useState<RespostaType | null>({alternativas: [], type: ''});
  const defaultQuestao = [
    {inicio: '', final: ''},
    {questao: ''},
    {questao: ''},
    {questao: ''},
    {questao: ''},
    {questao: ''},
    {questao: ''},
    {questao: ''},
    {inicio: '', final: ''},
  ];
  const defaultResposta = [
    {resposta: ''},
    {resposta: ''},
    {resposta: ''},
    {resposta: false},
    {resposta: false},
    {resposta: ''},
    {resposta: false},
    {resposta: ''},
    {resposta: ''},
  ];

  const navigate = useNavigate();

  const handleTypeChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    if(value != exerciseType){
      setExerciseType(value);
    }
  };

  const handleTitulo = (event) =>{
    if(data)
      setQuestao({...questao, titulo: event.target.value });
  }

  const onTypeChange = () => {
    setQuestao(null);
    setQuestao({
      titulo:  questao?.titulo || "",
      alternativas: [
        {alternativa: 'a', ...defaultQuestao[(exerciseType)-1]},
        {alternativa: 'b', ...defaultQuestao[(exerciseType)-1]},
      ],
      type: exerciseType,
    });
    setResposta({
      resposta:'', 
      type: exerciseType,
      alternativas: [
        {alternativa: 'a', ...defaultResposta[(exerciseType)-1]},
        {alternativa: 'b', ...defaultResposta[(exerciseType)-1]},
      ],
    });
  }

  const validAnswer = () => {
    let valid = true;
    let choose = false;
    
    if(resposta?.alternativas?.length > 0){
      resposta?.alternativas?.map((alternativa)=>{
        let rType = typeof alternativa.resposta;

        if(rType == 'boolean' && exerciseType != 5)
          choose = (choose || alternativa.resposta);

        if(rType == 'string' && alternativa.resposta?.trim().length < 1)
          valid = false;

        if(alternativa.resposta === undefined || alternativa.resposta === null)
          valid = false;

      });
    }

    if([4, '4', '7', 7].includes(exerciseType)){
      valid = choose;
    }

    return valid;
  }

  const validQuestion = () => {
    if(!questao?.titulo && questao?.titulo?.trim().length < 1){
      return false;
    }

    let valid = true;
    
    questao?.alternativas?.length > 0 &&
      questao.alternativas.map((alternativa)=>{
        if([1, '1', '9', 9].includes(exerciseType)){
          if(
            (!alternativa.inicio || alternativa.inicio.trim().length < 1) &&
            (!alternativa.final || alternativa.final.trim().length < 1)
          ){
            valid = false;
          }
        }else{
          if(!alternativa.questao || alternativa.questao.trim().length < 1){
            valid = false;
          }
        }
      });

    return valid;
  }

  let { id } = useParams();

  React.useEffect(() => {
    if (exerciseType && exerciseType !== questao.type) {
      onTypeChange();
      setData({...data, tipo_exercicio_id: exerciseType});  
    }
    // eslint-disable-next-line
  }, [exerciseType]); 

  React.useEffect(() => {
    if (resposta) {
      setData({...data, resposta, questao});
    }
    // eslint-disable-next-line
  }, [resposta, questao]);
  
  React.useEffect(() => {
    if (data?.tipo_exercicio_id && !exerciseType) {
      setExerciseType(data?.tipo_exercicio_id);
      setQuestao(data?.questao);    
      setResposta(data?.resposta);    
    }
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(()=>{
    if(!id){
      setExerciseType(0);
    }
    // eslint-disable-next-line
  },[types]);

  const disabled = React.useMemo(
    () => (
      !validAnswer()
      || !validQuestion()
      || !(exerciseType && exerciseType != 0)
      // eslint-disable-next-line
    ) ,[questao, resposta, exerciseType]);
  
  return (
    <Box
      className='col'
      id='container'
    >
      <Box
        className='row'
      >
          <Box
            className='col'
          >
            <TextField value={questao?.titulo??""} onChange={handleTitulo} label="Título*" variant="outlined" />
          </Box>
          <div style={{width: 20}}></div>
          <FormControl
            className='col'
          >
            <InputLabel id='tipoLabel'>Tipo*</InputLabel>
            {types && (exerciseType >= 0) &&
              <Select
                labelId='tipoLabel'
                id='tipoSelect'
                label="Tipo*"
                defaultValue={exerciseType}
                value={exerciseType}
                onChange={handleTypeChange}
              >
                {(types || []).map((type) => (
                  <MenuItem
                    value={type.value}
                  >
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            }
          </FormControl>
      </Box>
      <Box className='col'>
        { exerciseType > 0 &&
          <Box style={{marginTop: 30, marginBottom: 30}}>
            <strong>
              Alternativas
            </strong>
          </Box>
        }
        {(questao?.alternativas || [])?.map((_, index)=>(
          FormComponents(exerciseType, questao, setQuestao, resposta, setResposta, index)
        ))}

        <Box className='row' style={{ display: 'flex', marginTop: 10 }}>
          {questao.alternativas.length > 2 && (exerciseType > 0) &&
            <Button
              color="error"
              variant="contained"
              style={{ marginRight: 10 }}
              onClick={()=>{
                setQuestao({...questao, alternativas: questao.alternativas.slice(0, -1)});
                setResposta({...resposta, alternativas: resposta.alternativas.slice(0, -1)});
              }}
            >
              <Remove />
              Remover
            </Button>
          }

          {questao.alternativas.length < 6 && (exerciseType > 0) &&
            <Button
              color="primary"
              variant="contained"
              onClick={()=>{
                setQuestao({...questao, alternativas: [...questao.alternativas, {alternativa: String.fromCharCode(questao.alternativas.length+97), ...defaultQuestao[(exerciseType)-1]}]});
                setResposta({...resposta, alternativas: [...resposta.alternativas, {alternativa: String.fromCharCode(questao.alternativas.length+97), ...defaultResposta[(exerciseType)-1]}]});
              }}
            >
              <AddIcon />
              Adicionar
            </Button>
          }
        </Box>

      </Box>
      <Box className='row end' style={{marginTop: 30}} >
          <Button
            color="primary"
            variant="contained"
            onClick={()=> id ? onUpdate() : onSave()}
            disabled={disabled}
          >
            Salvar
          </Button>
          <div style={{width: 15}} />
          <Button
            style={{backgroundColor: '#888'}}
            variant="contained"
            onClick={()=>{navigate(-1)}}>
            Cancelar
          </Button>
      </Box>
    </Box>
  );
}