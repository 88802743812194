import { StudentProps } from "../../Page/Students/components/types";
import Api from "../Helpers/Api";

class StudentsService {
  getStudents() {
    return Api.get(`/alunos`);
  }

  updateStudent(student: StudentProps) {
    return Api.get(`/alunos/atualizar`, student);
  }
}

  export default new StudentsService();