import Api from "../Helpers/Api";

class LoginService {
  verifyHash(token: any) {
    return Api.get(`/verifytoken/${token}`);
  }

  verifySSOUser(token: any) {
    return Api.get(`/verifySSOuser/${token}`);
  }

  login(token: any) {
    return Api.get(`/loginWeb/${token}`);
  }
}

export default new LoginService();