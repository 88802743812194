import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Swal from 'sweetalert2';
import Toast from '../../../Common/Helpers/Toast';
import { successToast } from '../../../Common/Helpers/Utils';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { navigateToUrl } from 'single-spa';
import { useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { CustomGridToolbarProps } from '../../../@types/types';

type DataProps = {
  data: GridRowsProp;
  types: any;
  onDelete?: (id: GridRowId) => Promise<boolean>;
}

export default function CrudExercises({ data, types, onDelete }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  let { tarefa_id } = useParams();
  
  const handleClick = () => {
    navigateToUrl(`/tarefas/${tarefa_id}/exercicio/`);
  };

  const handleEditClick = (id: GridRowId='') => () => {
    navigateToUrl(`/tarefas/${tarefa_id}/exercicio/${id}`);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: 'Atenção',
      text: "Deseja mesmo apagar esse exercício?",
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await onDelete(id);
        if (response === true) {
          setRows(rows.filter((row) => row.id !== id));
          successToast('Tarefa apagada com sucesso!');
        } else {
          setOpen(true);
          setMessage('Erro ao apagar tarefa.');
        }
      }
    })
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  React.useEffect(() => {
    setRows(data);
  }, [data]);
  

  const columns: GridColDef[] = [
    {
      field: 'titulo',
      type: 'string',
      minWidth: 220,
      flex: 1,
      editable: true,
      renderHeader: () => <strong>Título</strong>,
    },
    {
      field: 'tipo_exercicio_id',
      minWidth: 150,
      flex: 1,
      editable: true,
      type: 'singleSelect',
      headerAlign: 'center',
      align: 'center',
      valueOptions: types,
      renderHeader: () => <strong>Tipo</strong>,
    },
    {
      field: 'criado_em',
      headerAlign: 'center',
      type: 'string',
      minWidth: 180,
      align: 'center',
      flex: 1,
      editable: true,
      renderHeader: () => <strong>Criado em</strong>,
    },
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Ações</strong>,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Editar"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Remover"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Toast
        message={message}
        open={open}
        setclose={setOpen}
        severity="error"
      />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        autosizeOptions={{
          columns: ['titulo', 'tipo_exercicio_id', 'criado_em'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhuma tarefa encontrada.',
          footerRowSelected: (count) => `${count} tarefa(s) selecionada(s)`,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onCellDoubleClick={(params, event)=>{event.defaultMuiPrevented = true;}}
        slots={{
          toolbar: ()=>(
            <GridToolbarContainer>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={() => navigateToUrl('/tarefas')}>
              Voltar
            </Button>
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleClick}>
                Adicionar
              </Button>
            </GridToolbarContainer>),
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Tarefas por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}