import Api from "../Helpers/Api";

class RoomsService {
  getRooms() {
    return Api.get(`/salas`);
  }

  getRoomsBySchool(id: string) {
    return Api.get(`/salas/escola/${id}`);
    // return Api.get(`/salas`);
  }

  saveRoom(data: any) {
    return Api.post(`/salas`, data);
  }

  saveHourRoom(data: any) {
    return Api.post(`/salas/horarios`, data);
  }

  updateRoom(data: any) {
    return Api.post(`/salas/update`, data);
  }

  updateHourRoom(data: any) {
    return Api.post(`/salas/horarios/update`, data);
  }

  deleteRoom(id: number) {
    return Api.get(`/salas/delete/${id}`);
  }

  deleteHourRoom(id: number) {
    return Api.get(`/salas/horarios/delete/${id}`);
  }

  getHours(id: number) {
    return Api.get(`/salas/horarios/${id}`);
  }
}

export default new RoomsService();
