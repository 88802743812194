import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers";
import logger from "redux-logger";

const store = configureStore({
  reducer: reducers,
  // Disable next line for Redux Logger - ETAPA
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type AppDispatch = typeof store.dispatch;

export default store;