import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import './FormExercises.css';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { navigateToUrl } from 'single-spa';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import FormComponents from './FormComponents';
import { ExercisesProps } from '../../Exercises/components/types';
import { useNavigate, useParams } from 'react-router-dom';

type DataProps = {
  data: ExercisesProps;
  setData: (data: ExercisesProps) => void;
  types: any;
  onUpdate: ()=>void;
  onSave: ()=>void;
}

type QuestaoType = {
  alternativas: any[];
  type: string | number;
  titulo?: string;
}


type RespostaType = {
  alternativas: any[];
  type: string | number;
  resposta?: string;
}

export default function FormExercises({ data, setData, types, onUpdate, onSave }: DataProps) {
  const [exerciseType, setExerciseType] = React.useState<string>();
  const [questao, setQuestao] = React.useState<QuestaoType | null>({alternativas: [], type: ''});
  const [resposta, setResposta] = React.useState<RespostaType | null>({alternativas: [], type: ''});

  const navigate = useNavigate()

  const handleTypeChange = (event: SelectChangeEvent) => {
    if(event.target.value != exerciseType){
      setExerciseType(event.target.value as string);
    }
  };

  const handleTitulo = (event) =>{
    if(data)
      setQuestao({...questao, titulo: event.target.value });
  }

  const onTypeChange = () => {
    setQuestao(null);
    switch(exerciseType+'') {      
      case '1':
      case '9': 
        setQuestao({
          titulo: questao?.titulo || "",
          alternativas: [
            {alternativa: 'a', inicio: '', final: ''},
            {alternativa: 'b', inicio: '', final: ''},
            {alternativa: 'c', inicio: '', final: ''},
            {alternativa: 'd', inicio: '', final: ''},
          ],
          type: exerciseType,
        });
        break;
      default:
        setQuestao({
          titulo:  questao?.titulo || "",
          alternativas: [
            {alternativa: 'a', questao: ''},
            {alternativa: 'b', questao: ''},
            {alternativa: 'c', questao: ''},
            {alternativa: 'd', questao: ''},
          ],
          type: exerciseType,
        });
        break;
    }

    switch(exerciseType+""){
      case '4':
      case '7':
        setResposta({
          resposta:'', 
          type: exerciseType,
          alternativas: [
            {alternativa: 'a', resposta: false},
            {alternativa: 'b', resposta: false},
            {alternativa: 'c', resposta: false},
            {alternativa: 'd', resposta: false},
          ],
      });
        break;
      case '5':
        setResposta({
          alternativas: [
            {alternativa: 'a', resposta: false},
            {alternativa: 'b', resposta: false},
            {alternativa: 'c', resposta: false},
            {alternativa: 'd', resposta: false},
          ],
          type: exerciseType,
        });
        break;
      default:
        setResposta({
          alternativas: [
            {alternativa: 'a', resposta: ''},
            {alternativa: 'b', resposta: ''},
            {alternativa: 'c', resposta: ''},
            {alternativa: 'd', resposta: ''},
          ],
          type: exerciseType,
        });
        break;
    }
  }

  const validAnswer = () => {
    let valid = true;
    let choose = false;
    
    resposta.alternativas.map((alternativa)=>{
      let rType = typeof alternativa.resposta;

      if(rType == 'boolean' && exerciseType != '5')
        choose = (choose || alternativa.resposta);

      if(rType == 'string' && alternativa.resposta?.trim().length < 1)
        valid = false;

      if(alternativa.resposta === undefined && alternativa.resposta === null)
        valid = false;

    });

    if([4, '4', '7', 7].includes(exerciseType)){
      valid = choose;
    }

    return valid;
  }

  const validQuestion = () => {
    if(!questao?.titulo && questao?.titulo?.trim().length < 1){
      return false;
    }

    let valid = true;
    
    questao.alternativas.map((alternativa)=>{

      if([1, '1', '9', 9].includes(exerciseType)){
        if(
          (!alternativa.inicio || alternativa.inicio.trim().length < 1) &&
          (!alternativa.final || alternativa.final.trim().length < 1)
        ){
          valid = false;
        }
      }else{
        if(!alternativa.questao || alternativa.questao.trim().length < 1){
          valid = false;
        }
      }
    });

    return valid;
  }

  let { id } = useParams();

  React.useEffect(() => {
    if (exerciseType && exerciseType != questao.type) {
      onTypeChange();    
      setData({...data, tipo_exercicio_id: parseInt(exerciseType)});  
    }
  }, [exerciseType]); 

  React.useEffect(() => {
    if (questao) {
      setData({...data, questao});  
    }
  }, [questao]); 
  
  React.useEffect(() => {
    if (resposta) {
      setData({...data, resposta});
    }
  }, [resposta]);
  
  React.useEffect(() => {
    if (data?.tipo_exercicio_id && !exerciseType) {
      setExerciseType(data?.tipo_exercicio_id+"");
      setQuestao(data?.questao);    
      setResposta(data?.resposta);    
    }
  }, [data]);

  React.useEffect(()=>{
    if(!id){
      setExerciseType('0');
    }
  },[types])
  

  const disabled = React.useMemo(()=> (!validAnswer() || !validQuestion() || !(exerciseType && exerciseType != '0')) ,[questao, resposta, exerciseType]);
  
  return (
    <Box
      className='col'
      id='container'
    >
      <Box
        className='row'
      >
          <Box
            className='col'
          >
            <TextField value={questao?.titulo??""} onChange={handleTitulo} label="Título*" variant="outlined" />
          </Box>
          <div style={{width: 20}}></div>
          <FormControl
            className='col'
          >
            <InputLabel id='tipoLabel'>Tipo*</InputLabel>
            {types && exerciseType &&
              <Select
                labelId='tipoLabel'
                id='tipoSelect'
                label="Tipo*"
                defaultValue={exerciseType}
                value={exerciseType}
                onChange={handleTypeChange}
              >
                {(types || []).map((type) => (
                  <MenuItem
                    value={type.value}
                  >
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            }
          </FormControl>
      </Box>
      <Box className='col'>
        { exerciseType && exerciseType != '0' &&
          <Box style={{marginTop: 30, marginBottom: 30}}>
            <strong>
              Alternativas
            </strong>
          </Box>
        }
        {(questao?.alternativas || [])?.map((_, index)=>(
          FormComponents(exerciseType, questao, setQuestao, resposta, setResposta, index)
        ))}
      </Box>
      <Box className='row end' style={{marginTop: 30}} >
          <Button
            color="primary"
            variant="contained"
            onClick={()=> id ? onUpdate() : onSave()}
            disabled={disabled}
          >
            Salvar
          </Button>
          <div style={{width: 15}} />
          <Button
            style={{backgroundColor: '#888'}}
            variant="contained"
            onClick={()=>{navigate(-1)}}>
            Cancelar
          </Button>
      </Box>
    </Box>
  );
}