import VideosTypes from "./Types";
import { VideoProps } from "../../Page/Videos/components/types";

const INITIAL_STATE = {
  Data: [] as VideoProps[],
  IsLoading: true,
  HasError: false,
};

export default function(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case VideosTypes.SET_VIDEOS_LOADING:
      return { ...state, IsLoading: true, HasError: false };
    case VideosTypes.SET_VIDEOS_SUCCESS:
      return { Data: action.payload, IsLoading: false, HasError: false };
    case VideosTypes.SET_VIDEOS_FAILURE:
      return { ...state, IsLoading: false, HasError: true };
    default: return state;
  }
}