import { QuestionProps } from "../../Page/Questions/components/types";
import Api from "../Helpers/Api";

class QuestionsService {
  getQuestions() {
    return Api.get(`/questions`);
  }

  saveQuestion(question: QuestionProps) {
    return Api.post(`/questions`, question);
  }

  updateQuestion(question: QuestionProps) {
    return Api.post(`/questions/update`, question);
  }

  deteleQuestion(id: number) {
    return Api.get(`/questions/delete/${id}`);
  }
}

export default new QuestionsService();