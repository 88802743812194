import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { CustomGridToolbarProps, LevelProps, ResponseProps } from '../../../@types/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../InfraStructure/store';
import { successToast } from '../../../Common/Helpers/Utils';
import Swal from 'sweetalert2';
import LevelsOperations from '../../../InfraStructure/Levels/LevelsOperations';
import Toast from '../../../Common/Helpers/Toast';
import { Choice, Level, PdfUploader } from '../../../Components/Medias';
import { CircularProgress } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

type DataProps = {
  data: GridRowsProp;
  onSave: (data: any) => Promise<ResponseProps>;
  onUpdate: (data: any) => Promise<ResponseProps>;
  onDelete: (id: GridRowId) => Promise<boolean>;
}

export default function CrudLivros({ data, onSave, onUpdate, onDelete }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [mode, setMode] = React.useState<'view' | 'edit' | 'new'>('view');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [loading,setLoading] = React.useState({ id: undefined, status: false });
  const [levels, setLevels] = React.useState<LevelProps[]>([]);
  const [level, setLevel] = React.useState<LevelProps>({ id: 0, nivel: '' });
  const [active, setActive] = React.useState<number | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { Data } = useSelector((state: any) => state.levels);

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [
        ...oldRows, {
          id,
          titulo: '',
          nivel_id: undefined,
          livro_estudante: '',
          livro_professor: '',
          ativo: 1,
          criado_em: new Date().toLocaleDateString(),
          isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'titulo' },
      }));

      setMode('new');
    };
  
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          disabled={loading.status || mode !== 'view'}
          onClick={handleClick}>
          Adicionar
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      setMode('view');
    }
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleValidationData = (data: any) => {
    if (!data.titulo) return { isValid: false, message: 'Título é obrigatório.' };
    if (!data.nivel_id) return { isValid: false, message: 'Nível é obrigatório.' };
    if (!data.livro_estudante) return { isValid: false, message: 'Livro do estudante é obrigatório.' };
    if (!data.livro_professor) return { isValid: false, message: 'Livro do professor é obrigatório.' };
    return { isValid: true, message: '' };
  }

  const handleEditClick = (id: GridRowId) => () => {
    if (mode === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      return;
    }
    setMode('edit');
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: 'Atenção',
      text: "Deseja mesmo apagar o Livro?",
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, apagar!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading({ id, status: true });
        const response = await onDelete(id);
        if (response === true) {
          setRows(rows.filter((row) => row.id !== id));
          successToast('Livro apagado com sucesso!');
          setLoading({ id, status: false });
        } else {
          setOpen(true);
          setMessage('Erro ao apagar o jogo.');
          setLoading({ id, status: false });
        }
      }
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setMode('view');
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleLevels = async () => {
    await dispatch(LevelsOperations.getLevels());
  }

  React.useEffect(() => {
    if (Data) {
      setLevels(Data);
    }
  }, [Data]);

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = {
      ...newRow,
      isNew: false,
      id: newRow.id,
      nivel_id: level.id,
      nivel: level.nivel,
    };

    if (mode === 'new') {
      const validation = handleValidationData(updatedRow);

      if (!validation.isValid) {
        setOpen(true);
        setMessage(validation.message);
        return;
      }

      setLoading({ id: newRow.id, status: true });

      const response = await onSave(updatedRow);

      if (response.status === 'success') {
        successToast(response.message);
        setLoading({ id: newRow.id, status: false });
        setMode('view');
        const created = { ...updatedRow, criado_em: response.data.criado_em };
        setRows(rows.map((row) => (row.id === newRow.id ? created : row)));
        return created;
      } else {
        setOpen(true);
        setMessage(response.message);
        setLoading({ id: newRow.id, status: false });
      }

    } else {
      setLoading({ id: newRow.id, status: true });

      const response = await onUpdate(updatedRow);

      if (response.status === 'success') {
        successToast(response.message);
        setLoading({ id: newRow.id, status: false });
        setMode('view');
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      } else {
        setOpen(true);
        setMessage(response.message);
        setLoading({ id: newRow.id, status: false });
      }
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleVerifyEditMode = (params: any) => {
    if (mode === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      
      handleCancelClick(params.id)();
      setMode('edit');
      return;
    } else {
      setMode('edit');
    }
  }

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  React.useEffect(() => {
    handleLevels();
    // eslint-disable-next-line
  }, []);

  const PdfIcon = () => (
    <Box style={{
      height: 40,
      width: '100%',
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        disabled
        style={{ width: 80, height: 40 }}>
        <PictureAsPdf height={40} />
      </Button>
    </Box>
  );

  const columns: GridColDef[] = [
    {
      field: 'titulo',
      minWidth: 190,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Título</strong>,
    },
    {
      field: 'nivel_id',
      minWidth: 120,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Nível</strong>,
      renderCell: (params) => {
        return levels.find((item: LevelProps) => item.id === Number(params.value))?.nivel;
      },
      renderEditCell: (params) => {
        const data = {
          ...params,
          levels,
          column: 'nivel_id',
        }
        return <Level params={data} setLevel={setLevel} />;
      },
    },
    {
      field: 'livro_estudante',
      minWidth: 150,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Livro Estudante</strong>,
      renderCell: (params) => <PdfIcon />,
      renderEditCell: (params) => (
        <PdfUploader params={params} field='livro_estudante' />
      ),
    },
    {
      field: 'livro_professor',
      minWidth: 150,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Livro Professor</strong>,
      renderCell: (params) => <PdfIcon />,
      renderEditCell: (params) => (
        <PdfUploader params={params} field='livro_professor' />
      ),
    },
    {
      field: 'ativo',
      type: 'string',
      minWidth: 100,
      flex: 1,
      editable: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Ativo</strong>,
      renderCell: (params) => (
        params.value ? 'Sim' : 'Não'
      ),
      renderEditCell: (params) => {
        const data = {
          ...params,
          height: 50,
        }
        return <Choice params={data} setActive={setActive} />
      },
    },
    {
      field: 'criado_em',
      headerAlign: 'center',
      type: 'string',
      minWidth: 180,
      flex: 1,
      editable: true,
      renderHeader: () => <strong>Criado em</strong>,
    },
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Ações</strong>,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={(loading.id === id && loading.status) ? <CircularProgress size={16} /> : <SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
              disabled={loading.status}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              disabled={loading.status}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={(loading.id === id && loading.status) ? <CircularProgress size={16} /> : <EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            disabled={loading.status}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            disabled={loading.status}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Toast
        message={message}
        open={open}
        setclose={setOpen}
        severity="error"
      />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        disableColumnMenu
        onRowDoubleClick={handleVerifyEditMode}
        columnVisibilityModel={{
          livro_estudante: mode !== 'view',
          livro_professor: mode !== 'view',
          criado_em: mode === 'view',
        }}
        autosizeOptions={{
          columns: [
            'titulo',
            'nivel',
            'livro_estudante',
            'livro_professor',
            'ativo',
            'criado_em',
          ],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhum livro encontrado.',
          footerRowSelected: (count) => `${count} livro(s) selecionado(s)`,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar as unknown as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Livros por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}