import { UnknownAction } from "redux";
import TeachersActions from "./Actions";
import TeachersService from "../../Common/Services/TeachersService";

interface Dispatch {
  (action: UnknownAction): void;
}

interface TeachersTypes {
  getTeachers: () => (dispatch: Dispatch) => Promise<void>;
}

const TeachersOperations: TeachersTypes = {
  getTeachers: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(TeachersActions.SetLoading());
    try {
      const response = await TeachersService.getTeachers();
      const listTeachers = response.data as any[];
      let teacherOrderByName: any[] = [];
      
      if (listTeachers.length > 0) {
        teacherOrderByName = listTeachers.sort((a: any, b: any) => {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        });
      }

      dispatch(TeachersActions.SetSuccess(teacherOrderByName));
    } catch (error: string | unknown) {
      console.log('Erro buscando professores: ', error);
      dispatch(TeachersActions.SetFailure());
    }
  }
}

export default TeachersOperations;