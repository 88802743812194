import { DocumentProps } from "./types";

export const documentList: DocumentProps[] = [
  {
    id: 1,
    nome: "Termos de uso",
    slug: "tos",
    url: "https://etapa-production.herokuapp.com/documents/tos",
    conteudo: "Termos de uso",
    criado_em: "2021-10-10",
  },
  {
    id: 2,
    nome: "Política de Privacidade",
    slug: "pp",
    url: "https://etapa-production.herokuapp.com/documents/pp",
    conteudo: "Política de Privacidade",
    criado_em: "2021-10-10",
  },
]