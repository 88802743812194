import Api from "../Helpers/Api";

class ChaptersServive {
  getChapters() {
    return Api.get(`/capitulos`);
  }

  createChapter(formData: FormData) {
    return Api.post(`/capitulo`, formData);
  }

  updateChapter(formData: FormData) {
    return Api.post(`/capitulo/update`, formData);
  }

  deleteChapter(id: number) {
    return Api.get(`/capitulo/delete/${id}`);
  }
}

export default new ChaptersServive();