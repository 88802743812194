import { UnknownAction } from "redux";
import { AppDispatch } from "../store";
import { ResponseProps } from "../../@types/types";
import LoginService from "../../Common/Services/LoginService";
import UserActions from "../User/Actions";
import { crypto } from "../../Components/User";

interface LoginOperationsType {
  verifyHash: (token: any) => Promise<ResponseProps>;
  verifySSOUser: (token: any) => Promise<ResponseProps>;
  login: (token: any) => (dispatch: AppDispatch) => Promise<void>;
}

const LoginOperations: LoginOperationsType = {
  verifyHash: async (token: any): Promise<ResponseProps> => {
    try {
      const response = await LoginService.verifyHash(token);
      return { status: 'success', message: 'Hash ok' };
    } catch (error) {
      console.log('Hash error', error);
      return { status: 'error', message: 'Hash inválida' };
    }
  },

  verifySSOUser: async (token: any): Promise<ResponseProps> => {
    try {
      const response = await LoginService.verifySSOUser(token);
      return { status: 'success', message: 'Usuário ok' };
    } catch (error) {
      console.log('User error', error);
      return { status: 'error', message: 'Erro de rede.' };
    }
  },

  login: (token: any) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(UserActions.SetLoading());
    try {
      const response = await LoginService.login(token);
      const data = response.data as {
        message: string,
        status: string,
        user: any,
        token: string,
        tokenB2C: string,
      };
      
      const userInfo = {
        user: data.user,
        token: data.token,
        tokenB2C: data.tokenB2C,
      }

      const userCrypto = crypto(JSON.stringify(userInfo));
      localStorage.setItem('userInfo', userCrypto);
      
      dispatch(UserActions.SetSuccess(data.user));
    } catch (error) {
      console.log('User error', error);
      dispatch(UserActions.SetFailure());
    }
  },
}

export default LoginOperations;