import HomeworksTypes from "./Types";

const INITIAL_STATE = {
  Data: {},
  IsLoading: true,
  HasError: false,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case HomeworksTypes.SET_HOMEWORKS_LOADING:
      return { ...state, IsLoading: true, HasError: false };
    case HomeworksTypes.SET_HOMEWORKS_SUCCESS:
      return { Data: {...state.Data, ...action.payload }, IsLoading: false, HasError: false };
    case HomeworksTypes.SET_HOMEWORKS_FAILURE:
      return { ...state, IsLoading: false, HasError: true };
    default: return state;
  }
}