import { Close, Description, ExpandMore, KeyboardArrowDown, Logout, Settings } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import React from "react";
import { navigateToUrl } from "single-spa";
import { menuEtapaIdioma } from "../Menu/components/menuConfig";
import theme from "../../Theme";
import { userData } from "../../Components/User";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [pathName, setPathName] = React.useState<string>("");
  const [windowsSize, setWindowsSize] = React.useState<number>(window.innerWidth);

  const logo = require("../../assets/media/logo.png");
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    navigateToUrl("logout");
  }

  const handleSettings = () => {
    setAnchorEl(null);
    navigateToUrl("configuracoes");
  }

  const { user } = userData();
  const { nome, email } = user;

  const firstLetterName = nome?.charAt(0)?.toUpperCase() || "E";

  const openDrawer = () => {
    setShowDrawer(!showDrawer);
  }

  const handleWindowSize = () => {
    setWindowsSize(window.innerWidth);
  }

  React.useEffect(() => {
    const urlPathName = window.location.pathname;
    setPathName(urlPathName);

    window.addEventListener("resize", handleWindowSize);

    return () => {
      window.removeEventListener("resize", handleWindowSize);
    }
  }, []);

  const DrawerList = (
    <Box sx={{
      width: 280,
      height: "100%",
      overflowX: "hidden",
      backgroundColor: theme.palette.secondary.main,
      msOverflowStyle: "none",
      scrollbarWidth: "none",
    }}>
      <Box
        sx={{
          width: 280,
          overflowY: "hidden",
          backgroundColor: theme.palette.secondary.main,
        }}
        role="presentation"
        onClick={() => {}}>
        <List>
          <Box sx={{
            width: "90%",
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            mt: 2,
            ml: 2,
          }}>
            <img src={logo} alt="Logo" width={90} />
            <Close 
              sx={{
                color: theme.palette.primary.light,
                position: "absolute",
                right: 10,
                top: 10,
              }}
              onClick={() => setShowDrawer(false)}
            />
          </Box>
          {menuEtapaIdioma.map((item, index) => (
            <ListItem
              key={`DR-${index}`}
              onClick={() => !item.subItem && navigateToUrl(item.route)}
              disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    backgroundColor: pathName === item.route
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.main,
                    color: pathName === item.route
                      ? theme.palette.secondary.contrastText
                      : theme.palette.primary.main,
                    padding: "0.5rem",
                    width: "95%",
                  }}>
                  {!item.subItem && item.show && (
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      {item.icon}
                      <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {item.label}
                      </Typography>
                    </Box>
                  )}

                  {item.subItem && (
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", m: -2 }}>
                      <Accordion
                        elevation={0}
                        sx={{ backgroundColor: theme.palette.secondary.main, width: "100%" }}>
                        <AccordionSummary
                          sx={{ color: theme.palette.primary.main }}
                          expandIcon={<ExpandMore style={{ color: theme.palette.primary.main }} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
                          {item.icon}
                          <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main, ml: 1 }}>
                            {item.label}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <List disablePadding sx={{ m: -2, ml: 1 }}>
                            {item.subItem.map((subItem: any, index: number) => (
                              <ListItem
                                key={`DR-${index}`}
                                onClick={() => navigateToUrl(subItem.route)}
                                disablePadding>
                                <ListItemButton>
                                  <ListItemIcon
                                    sx={{
                                      backgroundColor: pathName === subItem.route
                                        ? theme.palette.primary.dark
                                        : theme.palette.secondary.main,
                                      color: pathName === subItem.route
                                        ? theme.palette.secondary.contrastText
                                        : theme.palette.primary.main,
                                      padding: "0.5rem",
                                      width: "95%",
                                    }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                      {subItem.icon}
                                      <Typography variant="subtitle1" sx={{ ml: 1 }}>
                                        {subItem.label}
                                      </Typography>
                                    </Box>
                                  </ListItemIcon>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )

  return (
    <React.Fragment>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        boxShadow: 1,
        padding: 2,
      }}>
        <Tooltip title="Abrir Menu">
          {windowsSize <= 768 && (
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => openDrawer()}
            >
              <MenuIcon sx={{ width: 32 }} />
            </IconButton>
          )}
        </Tooltip>

        <Tooltip title="Account settings">
          <div onClick={handleClick} style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
            <Typography
              variant="body1"
              style={{
                marginTop: 10,
              }}
              fontStyle={{ color: "#A7A7A7" }}>
              {email}
            </Typography>
            <KeyboardArrowDown style={{ color: "#A7A7A7", marginTop: 10 }} />
            <IconButton
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>{firstLetterName}</Avatar>
            </IconButton>
          </div>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> Perfil
        </MenuItem> */}
        <MenuItem onClick={() => navigateToUrl("user")}>
          <Avatar /> Minha conta
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Gerenciar contas
        </MenuItem> */}
        <MenuItem onClick={handleSettings}>
          <ListItemIcon>
            <Description fontSize="small" />
          </ListItemIcon>
          Termos de Uso
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>

      <Drawer
        open={showDrawer}
        onClose={openDrawer}>
        {DrawerList}
      </Drawer>
    </React.Fragment>
  );
}