import React, { useEffect } from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import CrudHomeworks from "./components/EditableHomeworks";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import HomeworksOperations from "../../InfraStructure/Homeworks/HomeworksOperations";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import ChaptersOperations from "../../InfraStructure/Chapters/Operations";

export default function Homeworks() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>([]);
  const [chapters, setChapters] = React.useState<any[]>();
  const [types, setTypes] = React.useState<any[]>();

  const clusterSize = 1000;

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.homeworks
  );

  const Chapters = useSelector(
    (state: any) => state.chapters
  )?.Data;

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const uploadFile = async (file: any) => {
    const totalChunks = Math.ceil(file.size / (clusterSize * clusterSize));
    const fileId = Date.now().toString();
    let responseUpload: any;

    for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
      const start = chunkNumber * (clusterSize * clusterSize);
      const end = Math.min(file.size, (chunkNumber + 1) * (clusterSize * clusterSize));
      const chunk = file.slice(start, end);
      const extension = file.name.split('.').pop();

      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('totalChunks', totalChunks.toString());
      formData.append('chunkNumber', chunkNumber.toString());
      formData.append('fileId', fileId);
      formData.append('fileName', file.name);
      formData.append('extension', extension);

      try {
        responseUpload = await dispatch<any>(HomeworksOperations.uploadFile(formData));
        openToast('Enviada parte ' + (chunkNumber + 1) + ' de ' + totalChunks + ' com sucesso...');
      } catch (error: string | unknown) {
        console.log('Erro fazendo upload do arquivo: ', error);
      }
    }

    return responseUpload;
  }

  const handleSave = async (data: any) => {
    const fileSize = data.conteudo.audio?.size || 0;
    const formData = new FormData();

    try {
      if (fileSize > (clusterSize * clusterSize)) {
        const responseUpload = await uploadFile(data.conteudo.audio) as any;
        formData.append('big_audio', responseUpload.fileName);
      } else {
        formData.append('audio', data.conteudo.audio);
      }

      formData.append('tipo_tarefa_id', data.tipo_tarefa_id);
      formData.append('capitulo_id', data.capitulo_id);
      // formData.append('audio', data.conteudo.audio);
      if(data.conteudo.imagem) formData.append('imagem', data.conteudo.imagem);
      if(data.conteudo.texto) formData.append('texto', data.conteudo.texto);
      // if(data.conteudo.audio) formData.append('audio', data.conteudo.audio);

      const response = await dispatch<any>(HomeworksOperations.createHomework(formData));
      getHomeworks();
      return response;
    } catch (error: string | unknown) {
      console.log('Erro salvando capítulo: ', error);
      return { message: 'Erro na rede. Tente novamente...', status: 'error' };
    }
  }
  
  const handleUpdate = async (data: any) => {
    const fileSize = data?.conteudo?.audio?.size || 0;
    const formData = new FormData();
    formData.append('id', data.id);
    if(data?.tipo_tarefa_id) formData.append('tipo_tarefa_id', data.tipo_tarefa_id);
    if(data?.capitulo_id) formData.append('capitulo_id', data.capitulo_id);
    if(data.conteudo.imagem && typeof data.conteudo.imagem != 'string') formData.append('imagem', data.conteudo.imagem);
    if(data.conteudo.texto) formData.append('texto', data.conteudo.texto);
    if (fileSize && fileSize > (clusterSize * clusterSize) && typeof data.conteudo.imagem != 'string') {
      const responseUpload = await uploadFile(data.conteudo.audio) as any;
      formData.append('big_audio', responseUpload.fileName);
    } else {
      if(data.conteudo.audio && typeof data.conteudo.imagem != 'string') formData.append('audio', data.conteudo.audio);
    }

    try {
      const response = await dispatch<any>(HomeworksOperations.updateHomework(formData));
      return response;
    } catch (error: string | unknown) {
      console.log('Erro atualizando capítulo: ', error);
      return { message: 'Erro na rede. Tente novamente...', status: 'error' };
    }
  }

  const handleDelete = async (id: number): Promise<boolean> => {
    try {
      await dispatch<any>(HomeworksOperations.deleteHomework(id));
      return true;
    } catch (error: string | unknown) {
      console.log('Erro deletando capítulo: ', error);
      return false;
    }
  }

  const getHomeworks = async () => {
    try {
      await dispatch<any>(HomeworksOperations.getHomeworks());
      await dispatch<any>(HomeworksOperations.getTypes());
      await dispatch<any>(ChaptersOperations.getChapters());
    } catch (error: string | unknown) {
      console.log('Erro buscando tarefas: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  useEffect(() => {
    if (Data?.homeworks?.data && Data?.homeworks?.data.length > 0)
      setFilteredData((Data?.homeworks?.data || [])?.map((homework)=>({...homework, conteudo: homework.conteudo})));
    if (Data?.types?.data && Data?.types?.data.length > 0)
      setTypes((Data?.types?.data || [])?.map((type)=>({value: type.id, label: type.tipo}))||[]);
  }, [Data]);

  useEffect(() => {
    if (Chapters && Chapters.length > 0) {
      setChapters((Chapters ?? [])?.map((chapter)=>({value: chapter.id, label: /*chapter.nivel+" - "+*/chapter.nome, name: chapter.nome+" - "+chapter.nivel })));
    }
  }, [Chapters]);

  useEffect(() => {
    getHomeworks();
    // eslint-disable-next-line
  }, []);

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar tarefas' onClick={getHomeworks} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />
      <Toast message={message} open={open} setclose={setOpen} severity="success" />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Tarefas
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={270}
              placeHolder="Buscar por capítulo ou tipo"
              columnsToSearch={['capitulo_nome', 'tipo_tarefa']}
              data={Data?.homeworks?.data}
              setSearch={setFilteredData}
            />
          </Box>
          
          {IsLoading ? 
            <SkeletonGrid /> : 
            <CrudHomeworks 
              data={filteredData} 
              chapters={chapters} 
              types={types}
              onSave={handleSave}
              onDelete={handleDelete}
              onUpdate={handleUpdate}
            />}
        </Box>
      </Box>
    </React.Fragment>
  );
}