import React, { useEffect } from "react";
import { userData } from "../../Components/User";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import {
  CheckBox,
  CheckBoxOutlineBlankOutlined,
  CheckCircleOutline,
  ExitToApp,
  Warning,
} from "@mui/icons-material";
import AccountMenu from "../AccountMenu";
import sign from "jwt-encode";

const sso_url = 'https://material.etapadigital.com.br/sso?jwt=';

const EtapaDigital = () => {
  const [ssoToken, setSsoToken] = React.useState(undefined);
  const [ssoAceite, setSsoAceite] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { user, tokenB2C } = userData();
  const { acesso_app_etapa_digital } = user;
  const SSOEtapaKey = "36F0D3B566A749708120B52471F9F1B2";

  const verificarAcesso = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://aluno.sistemaetapa.com.br/api/etapa-digital/verificar`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenB2C}`,
          },
          body: JSON.stringify({
            check_confirmado: ssoAceite,
            numero: user.id,
            portal: 'parceiro',
          }),
        }
      );
      const data = await response.json();
      setIsLoading(false);
      if (data?.token) {
        setSsoToken(data.token);
      }
    } catch (error) {
      console.log('Error verificar acesso', error);
      setIsLoading(false);
    }
  };

  const generateTokenEtapaDigital = async (digitalKey: string) => {
    const payload = {
      token: digitalKey,
    }
    const token = sign(
      payload,
      SSOEtapaKey
    );

    return token;
  }

  const Declaracao = () => (
    <div>
      <Typography
        variant="subtitle1"
        sx={{
          color: '#663D0C',
          fontSize: 14,
        }}>
          Declaro que li e aceito os &nbsp;
          <a href="https://www.etapadigital.com.br/termos.html" target="_blank" rel="noreferrer">
            Termos de Uso
          </a>
          &nbsp;do <strong>Etapa Digital</strong>.
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#142878',
          color: '#FCD037',
          fontSize: 12,
          fontWeight: 'bold',
          marginTop: 2,
        }}
        disabled={!ssoAceite}>
          <ExitToApp sx={{ marginRight: 1, fontSize: 20 }} />
          {isLoading
            ? 'Ativando acesso...'
            : 'Ativar acesso'
          }
      </Button>
    </div>
  );

  const Acessar = () => (
    <div>
      <Typography
        variant="subtitle1"
        sx={{
          color: 'text.primary',
          fontSize: 14,
          marginLeft: 4,
        }}>
        Tenha acesso às suas apostilas de forma rápida e fácil.
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#142878',
          color: '#FCD037',
          fontSize: 12,
          fontWeight: 'bold',
          marginTop: 2,
          marginLeft: 4,
        }}
        disabled={!ssoToken}
        onClick={() => {
          generateTokenEtapaDigital(ssoToken).then((token) => {
            window.open(`${sso_url}${token}`, '_blank');
        })}}>
          <ExitToApp sx={{ marginRight: 1, fontSize: 20 }} />
          Acessar Material
      </Button>
    </div>
  );

  const SubTitle = () => (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}>
      {(acesso_app_etapa_digital === 1) &&
        !ssoToken
          ? <Warning sx={{
              color: '#5EB761',
              fontSize: 25,
              marginRight: 1,
              }} />
          : <CheckCircleOutline sx={{
              color: '#5EB761',
              fontSize: 25,
              marginRight: 1,
              }} />
      }
      <Typography
        variant="h5"
        sx={{
          color: 'text.primary',
          fontWeight: 'bold',
          fontSize: 20,
        }}>
          {acesso_app_etapa_digital === 1
            ? 'Acesso ao ETAPA Digital'
            : ssoToken ? 'Acesso ao ETAPA Digital' : 'Ativar acesso'
          }
      </Typography>
    </Box>
  );

  const Header = () => (
    <Typography
      variant="h1"
      sx={{
        color: 'text.primary',
        fontWeight: 'bold',
        fontSize: 33,
        marginTop: 4,
        marginLeft: 4,
      }}>
        <Box sx={{
          borderBottom: '2px solid #FCD037',
          padding: 1,
          borderWidth: 2,
          display: 'inline-block',
        }}>
          Painel Etapa Digital
        </Box>
    </Typography>
  );

  const CheckAceite = () => (
    !ssoAceite
      ? <CheckBoxOutlineBlankOutlined
          onClick={() => setSsoAceite(!ssoAceite)}
          sx={{
            color: '#663D0C',
            fontSize: 25,
            marginLeft: 4,
            marginRight: 1,
            cursor: 'pointer',
          }}
        />
      : <CheckBox
          onClick={() => setSsoAceite(!ssoAceite)}
          sx={{
            color: '#663D0C',
            fontSize: 25,
            marginLeft: 4,
            marginRight: 1,
            cursor: 'pointer',
          }}
        />
  );

  useEffect(() => {
    setIsLoading(true);
    if (acesso_app_etapa_digital === 0) {
      setIsLoading(false);
    } else {
      verificarAcesso();
    }
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ flex: 1 }}>
        <Header />
        <Box sx={{ justifyContent: 'center', margin: 4 }}>
          <Skeleton
            variant="rectangular"
            width="90%"
            height={30}
            sx={{ borderRadius: 2 }}
          />
          <Skeleton
            variant="rectangular"
            width="60%"
            height={30}
            sx={{ borderRadius: 2, marginTop: 2 }}
          />
          <Skeleton
            variant="rectangular"
            width="30%"
            height={30}
            sx={{ borderRadius: 2, marginTop: 2 }}
          />
        </Box>
      </Box>
    )
  } else {
    return (
      <Box sx={{ flex: 1 }}>
        <AccountMenu />
        <Header />

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 4 }}>
          <Box sx={{
            width: '90%',
            marginTop: 2,
            border: '1px solid #FCD037',
            padding: 2,
          }}>
            <SubTitle />

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 2,
            }}>
              {acesso_app_etapa_digital === 1
                && !ssoToken && <CheckAceite />}

              {(acesso_app_etapa_digital !== 1) ? (
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: '#663D0C',
                    fontSize: 14,
                    marginLeft: 4,
                  }}>
                    Sem acesso ao <strong>Etapa Digital</strong>. Procure a secretaria do colégio.
                </Typography>
              ) : (
                !ssoToken
                  ? <Declaracao />
                  : <Acessar />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default EtapaDigital;
